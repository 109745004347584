import { graphql, Link, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import * as React from 'react';
import { useEffect } from 'react';
import { IoIosStar } from 'react-icons/io';

import { Layout, SEO } from '../components';
import FirstSection from '../components/first-section';
import SEOPageContactForm from '../components/forms/seo-contact-form';
import { Hero } from '../components/landing-hero';
import { ThreeIcons } from '../components/local-removalist';
import SecondSection from '../components/second-section';
import { IoIosCheckmark } from 'react-icons/io';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityPost(sort: { fields: _createdAt }) {
        nodes {
          _createdAt(formatString: "MMMM DD, YYYY")
          body
          categories {
            title
          }
          description
          id
          mainImage {
            asset {
              fluid(maxWidth: 400) {
                ...GatsbySanityImageFluid
              }
            }
          }
          slug {
            current
          }
          title
        }
      }
      file(name: { eq: "landing-banner" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100, traceSVG: { color: "#152735" }) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const testimonials = [
    {
      quote:
        'From quote to delivery, Frontline were excellent. Despite difficult delivery terrain, the guys were professional and very understanding. Would highly recommend their services.',
      name: 'Mark Langdon',
    },
    {
      quote:
        "Best removal company we've ever used. Excellent service. They were on time, easy to deal with and their staff took great care moving and packing all of our items in the truck.",
      name: 'Matt Ward',
    },
    {
      quote:
        "Had these guys move me from house to house. Nothing broken, nothing damaged. I'm a very picky person and will pick anything to bits. Unreal service from these guys. Thank you very much.",
      name: 'Chris Baker',
    },
  ];

  useEffect(() => {
    if (typeof window !== 'undefined') {
      var f = document.createElement('iframe');
      f.src =
        'https://forms.zohopublic.com.au/frontlineremovals/form/FrontlineRemovals/formperma/hkM77lTI53lWl457vreSUTKi8D0WYcvzzEB7zeK11BM?zf_rszfm=1';
      f.style.border = 'none';
      f.style.height = '600px';
      f.style.width = '100%';
      f.style.transition = 'all 0.5s ease'; // No I18N
      var d = document.getElementById('landing-moving-quote-form');
      d.appendChild(f);
    }
  }, []);

  return (
    <Layout isSuccess hideForm footerIsGray formName="home-page-bottom-form">
      <SEO
        title="Interstate Removalists in NSW | Frontline Removals & Storage"
        description="Your trusted Removalists in NSW. Frontline Removals is located in Port Macquarie on the mid-North Coast. The perfect base for Brisbane & Sydney removals.
        "
      />
      <Hero />
      <article className="px-4 py-12 mx-auto text-white sm:px-12 bg-brand-blue max-w-1440">
        <div className="">
          <h2 className="mb-12 text-5xl font-semibold leading-none text-center uppercase font-display">
            What Our <span className="text-brand-orange">Clients Say</span>
          </h2>
        </div>
        <div className="flex flex-wrap">
          {testimonials.map((testimonial) => (
            <div className="w-full px-12 mt-6 md:mt-0 md:w-1/3">
              <span
                aria-hidden="true"
                className="flex justify-center mb-6 text-4xl text-white"
              >
                <IoIosStar />
                <IoIosStar />
                <IoIosStar />
                <IoIosStar />
                <IoIosStar />
              </span>
              <div className="text-center rich-text">
                {testimonial.name === 'Matt Ward' ? (
                  <p>
                    Best removal company we have ever used. Excellent service.
                    They were on time, easy to deal with and their staff took{' '}
                    <Link to="https://www.frontlineremovals.com.au/kempsey-furniture-removals/">
                      great care moving and packing
                    </Link>{' '}
                    all of our items in the truck.
                  </p>
                ) : (
                  testimonial.quote
                )}
              </div>
              <div className="mt-3 italic text-center rich-text">
                {testimonial.name}
              </div>
            </div>
          ))}
        </div>
      </article>
      <div className="bg-brand-orange">
        <div className="flex flex-col justify-between max-w-6xl px-6 py-4 mx-auto space-x-8 md:flex-row">
          <h2 className="text-4xl font-semibold leading-none text-center text-white uppercase font-display md:text-left">
            learn how we Manage Infection control IN our WORKPLACE
          </h2>

          <Link
            to="/covid-19/"
            className="flex items-center self-center justify-center w-full max-w-xs mt-6 whitespace-nowrap button-white md:mt-0"
          >
            Read more here
          </Link>
        </div>
      </div>
      <FirstSection />
      <div className="relative max-w-6xl mx-auto my-auto mt-6 mb-12 overflow-hidden">
        <GatsbyImage
          fluid={data.file.childImageSharp.fluid}
          alt="Landing page banner"
        />
        <div className="absolute inset-0 justify-center hidden px-12 py-4 sm:flex sm:flex-col">
          <h2 className="mb-6 text-5xl font-semibold leading-none uppercase font-display">
            Our relocation services <br /> think outside the box
          </h2>
          <p>
            <Link
              to="/furniture-removal/"
              className="px-12 py-1 mt-12 font-semibold text-black bg-white rounded hover:shadow-md"
            >
              Get in touch
            </Link>
          </p>
        </div>
      </div>

      <SecondSection />
      <div className="mt-16" />
      <ThreeIcons />
      <div className="w-full max-w-5xl mx-auto my-auto mt-16 text-center">
        <p className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
          <span className="inline-block text-brand-orange">Learn more </span>
          <span className=""> About us</span>
        </p>
      </div>

      <div className="grid w-full grid-cols-1 gap-12 px-4 mt-8 mb-4 max-w-none sm:mt-8 lg:grid lg:grid-cols-3 lg:gap-12">
        {data.allSanityPost.nodes.map(
          (post, i) =>
            i < 3 && (
              <div
                key={post.id}
                className="flex duration-300 ease-in-out transform bg-white rounded-lg shadow hover:shadow-xl hover:scale-105"
              >
                <div className="flex flex-col flex-1 overflow-hidden rounded-lg shadow-sm">
                  <Link
                    to={`/blog/${post.slug.current}/`}
                    className="focus:outline-none group"
                  >
                    <GatsbyImage
                      fluid={post.mainImage.asset.fluid}
                      className="object-cover w-full h-64"
                      alt={`Blog post - ${post.title}`}
                    />
                    <div className="px-6 mt-4">
                      <h3
                        title={post.title}
                        className="text-3xl leading-tight line-clamp-1 text-brand-blue font-display group-focus:underline"
                      >
                        {post.title}
                      </h3>
                      <div className="mt-4">
                        <p title={post.description} className="line-clamp-3">
                          {post.description}
                        </p>
                      </div>
                    </div>
                  </Link>
                  <p className="px-6 pt-2 my-4 text-gray-600">
                    <time>{post._createdAt}</time>
                  </p>
                  <div className="flex flex-wrap items-start flex-1 px-6 mt-auto">
                    <div className="py-4">
                      {post.categories.map((category) => (
                        <button
                          type="button"
                          key={category.title}
                          className={`inline-block px-3 py-1 mt-4 mr-2 text-sm font-semibold text-white transition duration-150 ease-in-out rounded hover:bg-brand-blue focus:outline-none focus:ring focus:bg-brand-blue ${'bg-brand-blue'}`}
                        >
                          #{category.title}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )
        )}
      </div>
      <div className="w-full max-w-5xl mx-auto my-auto mt-16 text-center">
        <p className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
          {/* <span className="inline-block text-brand-orange">Contact Us </span> */}
        </p>
      </div>
      <div className="relative max-w-4xl mx-auto">
        {/* <SEOPageContactForm formName="landing-moving-quote-form" /> */}
        <div id="">        <div
          style={{
            marginLeft: '30px',
            marginRight: '30px',
            marginBottom: '-30px',
          }}
          className="pt-4 bg-brand-blue"
        >
          <div className="flex flex-wrap justify-center">
            <h3 className="flex items-center">
              <IoIosCheckmark className="flex-shrink-0 text-2xl text-white md:text-5xl" />
              <span className="text-sm font-semibold text-brand-orange md:text-lg">
                30+ Years Experience
              </span>
            </h3>
            <h3 className="flex items-center">
              <IoIosCheckmark className="flex-shrink-0 text-2xl text-white md:text-5xl" />
              <span className="text-sm font-semibold text-brand-orange md:text-lg">
                Caring & Reliable
              </span>
            </h3>
            <h3 className="flex items-center">
              <IoIosCheckmark className="flex-shrink-0 text-2xl text-white md:text-5xl" />
              <span className="text-sm font-semibold text-brand-orange md:text-lg">
                Excellent Value
              </span>
            </h3>
          </div>
          <h2 className="text-5xl font-semibold leading-none text-center text-white uppercase md:text-7xl font-display">
            Get a <span className="text-brand-orange">fast quote</span> now
          </h2>
          <p className="text-sm font-semibold text-center text-white md:text-xl">
            Complete the{' '}
            <span className="text-brand-orange">Quick Quote Form</span> Below to{' '}
            <span className="text-brand-orange">Save</span> on Your Move
          </p>
        </div>
        <div id="landing-moving-quote-form"></div></div>
      </div>
    </Layout>
  );
};

export default IndexPage;
