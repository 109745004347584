import { Link } from 'gatsby';
import * as React from 'react';

import LiCheck from './utils/li-check';

const FirstSection = () => (
  <article className="flex flex-wrap max-w-6xl px-6 py-4 mx-auto mt-20">
    <div className="w-full md:w-1/3">
      <h1 className="text-5xl font-semibold leading-none uppercase heading font-display">
        <span className="inline-block">Interstate </span>
        <br />
        <span className="inline-block">
          Removalists
          <br />
          in NSW
        </span>
      </h1>
    </div>
    <div className="w-full mt-6 md:mt-0 md:w-2/3">
      <div className="rich-text">
        <h2>
          <Link to="https://www.frontlineremovals.com.au/furniture-removal/">
            Your team of choice for
          </Link>{' '}
          <Link to="https://www.frontlineremovals.com.au/grafton-furniture-removals/">
            reliable removal services
          </Link>
        </h2>
        <p>
          With our head office based in Port Macquarie on the mid-North Coast of
          New South Wales — we’re conveniently situated between the hubs of
          Sydney and Brisbane.
        </p>
        <p>
          We’re your{' '}
          <Link to="/port-macquarie-furniture-removals/">
            trusted removalists in Port Macquarie
          </Link>{' '}
          for home relocation and storage. We’re solution specialists with
          expertise in removal services — including container shipping, storage
          and purpose-built containers for purchase.
        </p>
        <h3>
          At Frontline Removals, we’re the perfect team for customers seeking:
        </h3>
        <ul>
          <LiCheck>Efficient, safe, and reliable home relocation</LiCheck>
          <LiCheck>Secure, cost-effective storage options</LiCheck>
          <LiCheck>Multi-purpose shipping containers for sale</LiCheck>
        </ul>
      </div>
    </div>
  </article>
);

export default FirstSection;
