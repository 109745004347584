import { Link } from 'gatsby';
import * as React from 'react';

const SecondSection = () => (
  <article className="flex flex-wrap max-w-6xl px-6 py-4 mx-auto mt-20">
    <div className="w-full md:w-1/3">
      <h2 className="text-5xl font-semibold leading-none uppercase heading font-display">
        <span className="inline-block">Why </span>
        <br />
        <span className="inline-block">move with </span>
        <br />
        <span className="inline-block text-brand-orange">Frontline?</span>
      </h2>
    </div>
    <div className="w-full mt-6 md:mt-0 md:w-2/3">
      <div className="rich-text">
        <h3>
          New South Wales{' '}
          <Link to="https://www.frontlineremovals.com.au/port-macquarie-furniture-removals/">
            trusted removalist
          </Link>{' '}
          company for more than {new Date().getFullYear() - 1995} years
        </h3>
        <p>
          At Frontline, we go above-and-beyond industry standards. We combine
          over two decades of hands-on experience with family values and a
          passion for customer service.
        </p>
        <h3>Streamlined container shipping and storage</h3>
        <p>
          Leaders in our industry, we bridge the gap of double-handling.
          Utilising our niche container shipping method, your items are stored
          and shipped all in one place!
        </p>
        <h3>Security and service at the heart of Frontline</h3>
        <p>
          As a team, we’re highly efficient in packing, handling and{' '}
          <Link to="https://www.frontlineremovals.com.au/ballina-furniture-removals/">
            shipping furniture
          </Link>{' '}
          and personal effects. Following strict security and service values, we
          protect your belongings on a 24 hour basis.
        </p>
        <h3>We take the headache out of home relocation</h3>
        <p>
          Our team takes care of every detail for you. From paperwork, to
          handling and streamlined removal services — we take the stress out of
          moving with a timely, efficient approach.
        </p>
      </div>
      <p>
        <Link
          to="/furniture-removal/"
          className="inline-block px-12 py-1 mt-6 font-semibold text-white border-2 border-transparent rounded shadow hover:shadow-md bg-brand-blue"
        >
          Find out more
        </Link>
      </p>
    </div>
  </article>
);

export default SecondSection;
