import { graphql, Link, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';
// @ts-ignore
import Helmet from 'react-helmet';
import {
  IoIosCall,
  IoIosCheckmark,
  IoIosLink,
  IoIosStar,
  IoIosStarHalf,
} from 'react-icons/io';

import withLocation from '../with-location';

const capitalise = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const HeroCTA = withLocation(({ search }) => {
  const { from, to, location, interstate } = search;
  const isBrowser = typeof window !== 'undefined';

  const doFB = () => {
    const eventID = crypto.randomUUID();
    console.log(eventID);
    conversionsAPI(eventID);
    if (isBrowser && window.fbq)
      window.fbq('track', 'Contact', {}, { eventID });
  };

  async function conversionsAPI(eventID) {
    const cookies = document.cookie.split(';');
    let fbp = 'none';
    let fbc = 'none';

    cookies.map((cookie) => {
      if (cookie.includes('_fbp=')) {
        fbp = cookie.slice(cookie.indexOf('_fbp=') + 5);
        console.log(fbp);
      }
    });
    cookies.map((cookie) => {
      if (cookie.includes('_fbc=')) {
        fbc = cookie.slice(cookie.indexOf('_fbc=') + 5);
        console.log(fbc);
      }
    });

    if (fbc === 'none' && window.location.search.includes('fbclid=')) {
      const params = new URL(document.location).searchParams;
      fbc = `fb.1.${Date.now()}.${params.get('fbclid')}`;
    }
    try {
      const res = await fetch('/.netlify/functions/conversions-api', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ eventType: 'Contact', fbp, fbc, eventID }),
      });
      const data = await res.json();
      console.log('Return from netlify functions conversionsAPI =', data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          phone
          phoneFormatted
          googleMapsAddress
        }
      }
    }
  `);

  const { phone, phoneFormatted, googleMapsAddress } = data.site.siteMetadata;

  const CTA = () => {
    if (from && to) {
      return (
        <p className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
          <span className="">Experienced</span> <br />
          {capitalise(from)} to <br />
          {capitalise(to)} <br /> removalist
        </p>
      );
    }
    if (location) {
      return (
        <p className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
          <span className="">Experienced</span> <br />
          {capitalise(location)}
          {interstate && (
            <>
              <br /> Interstate
            </>
          )}
          <br /> removalist
        </p>
      );
    }
    return (
      <p className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
        <span className="">Experienced</span> <br />
        interstate and <br />
        regional removalist
      </p>
    );
  };
  return (
    <article className="w-full max-w-5xl mx-auto my-auto">
      {from && to && (
        <Helmet
          title={`Experienced ${capitalise(from)} to ${capitalise(
            to
          )} Removalist`}
        />
      )}
      {location && (
        <Helmet title={`Experienced ${capitalise(location)} Removalist`} />
      )}

      <div className="flex">
        <div className="p-3 -m-3 overflow-hidden rounded-lg cta">
          <CTA />
          <div className="mt-3">
            {[
              {
                id: 1,
                title: '30+ years experience',
              },
              {
                id: 2,
                title: 'Caring & reliable service',
              },
              {
                id: 3,
                title: 'Packing available',
              },
              {
                id: 4,
                title: 'Excellent value',
              },
            ].map((item) => (
              <div key={item.id} className="flex items-center font-semibold">
                <IoIosCheckmark className="flex-shrink-0 -ml-2 text-4xl text-brand-orange" />
                <span className="flex-shrink-0 ml-1 uppercase">
                  {item.title}
                </span>
              </div>
            ))}
            <a
              href={googleMapsAddress}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center font-semibold hover:text-brand-orange"
            >
              <IoIosCheckmark className="flex-shrink-0 -ml-2 text-4xl text-brand-orange" />
              <span className="flex items-center flex-shrink-0 ml-1 uppercase">
                4.7&nbsp;
                <span aria-hidden="true" className="flex text-brand-orange">
                  <IoIosStar />
                  <IoIosStar />
                  <IoIosStar />
                  <IoIosStar />
                  <IoIosStarHalf />{' '}
                </span>
                <span className="ml-1"> on Google</span>
                <span className="ml-1">
                  <IoIosLink />
                </span>
              </span>
            </a>
          </div>

          <div className="flex flex-wrap max-w-lg mt-2 -mx-2 space-y-4">
            <div className="flex flex-wrap items-center justify-start w-full">
              {/* <p className="w-full px-2 mt-4 ">
                <Link
                to="/inventory-form/#inventoryform"
                // @ts-ignore
                offset="12"
                className="flex items-center justify-center shadow whitespace-nowrap w-72 hover:shadow-md button bg-brand-blue"
                >
                Fill Out Inventory Form
                </Link>
              </p> */}
              <p className="w-full px-2 mt-4 ">
                <a
                  onClick={() => doFB()}
                  href={`tel:${phone}`}
                  className="flex items-center justify-center shadow whitespace-nowrap w-72 hover:shadow-md button"
                >
                  {/* <IoIosCall /> */}
                  <span className="text-sm">Call for BEST Price </span><span>&nbsp;{phoneFormatted}</span>
                </a>
              </p>
              <p className="w-full px-2 mt-4 ">
                <Link
                  to="/furniture-removal/#quote-form"
                  // @ts-ignore
                  offset="0"
                  className="flex items-center justify-center text-black bg-white border-black shadow whitespace-nowrap w-72 hover:shadow-md button"
                >
                  <span className='text-sm'>Or Get An&nbsp;</span>Online Quote
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
});

// @ts-ignore
HeroCTA.propTypes = {
  search: PropTypes.object,
};

export { HeroCTA };
